import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultTaxData = {
  id: 0,
  enabled: true,
  name: '',
  countryId: undefined,
  rate: 0
};
export var getTaxes = function getTaxes(params) {
  return request({
    url: '/taxes',
    method: 'get',
    params: params
  });
};
export var getTaxById = function getTaxById(id) {
  return request({
    url: "/taxes/".concat(id),
    method: 'get'
  });
};
export var updateTax = function updateTax(id, data) {
  return request({
    url: "/taxes/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteTax = function deleteTax(id) {
  return request({
    url: "/taxes/".concat(id),
    method: 'delete'
  });
};
export var createTax = function createTax(data) {
  return request({
    url: '/taxes/',
    method: 'post',
    data: data
  });
};